(function() {

	'use strict';

	//const leaflet_path = "/dashboard/node_modules/leaflet/dist";
	const leaflet_path = "https://unpkg.com/leaflet@1.7.1/dist";

	class MapService {

		static $inject = [ "$q", "$http" ];

		constructor( $q, $http ) {

			var __loadPrms;

			var self = this;

			var enabled = false;

			self.enable = function() {

				try {

					return $q.when( enabled ).then(function( enabled ) {

						if (enabled) return;

						return self.Leaflet || __loadPrms || (__loadPrms = $q.all([
							jQuery.getScript( leaflet_path + "/leaflet.js" ).then(function(){

								if (!window.L) throw "Missing Leaflet";

								self.Leaflet = window.Leaflet = window.L;

								delete window.L

								window.Leaflet.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.7.1/dist/images/";

							}),
							$http.get( leaflet_path + "/leaflet.css").then(function( result ) {
								$("<style>").html( result.data ).appendTo( document.head );
							})
						]));
					}).then(function() {

						enabled = true;

						return self;
					});

				} catch (error) {

					console.error( error );

					return $q.reject( error );
				}
			};

			self.solveCoordinates = function( coordinates ) {

				if (angular.isString( coordinates ) || coordinates instanceof String) {

					try {
						return self.solveCoordinates( JSON.parse( coordinates ));
					} catch (error) {}

					return coordinates.split( "," );

				} else if (angular.isObject( coordinates )) {

					if (angular.isArray( coordinates ) && coordinates.length == 2) {

						return [
							parseFloat( coordinates[0] ),
							parseFloat( coordinates[1] ),
						];

					} else if (coordinates.lat != null && coordinates.lng != null) {
					
						return [
							parseFloat( coordinates.lat ),
							parseFloat( coordinates.lng )
						];
					}
				}
			};

			self.generateMap = function( target, options ) {
				return self.enable().then(function() {

					target.style.width = target.style.paddingTop = "100%";

					var map = self.Leaflet.map( target, angular.extend({
						center: [ 61.49911, 23.78712 ],
						zoom: 13
					}, options ));

					self.Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
						attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					}).addTo( map );

					return map;
				});
			};
		}
	}

	class MapComponentViewDirective {

		static $inject = [ "mapService" ];

		constructor( mapService ) {
			return {
				template: "<div></div>",
				scope: { mapComponentView: "@" },
				link: function( scope, element, attrs, tabsCtrl ) {

					var coordinates;

					if (scope.mapComponentView) coordinates = mapService.solveCoordinates( scope.mapComponentView );

					if (!coordinates) return;

					mapService.generateMap( element[0].children[0], { center: coordinates }).then(function( map ) {

						mapService.Leaflet.marker( coordinates ).addTo( map );
					});
				}
			};
		}
	}

	class MapComponentCoordinatesEditorDirective {

		static $inject = [ "mapService", "$timeout" ];

		constructor( mapService, $timeout ) {

			return {
				template: '<div></div>'+
					//'<div>{{ place | json }}</div>'+
					'<div class="mt-2 row">'+
						'<div class="col-xs-6">'+
							'<div class="input-group">'+
								'<span class="input-group-addon">Leveyspiiri</span>'+
								'<input type="number" step="0.0000000000001" class="form-control" ng-model="coordinates[0]" ng-change="setCoordinates(coordinates)">'+
							'</div>'+
						'</div>'+
						'<div class="col-xs-6">'+
							'<div class="input-group">'+
								'<span class="input-group-addon">Pituuspiiri</span>'+
								'<input type="number" step="0.0000000000001" class="form-control" ng-model="coordinates[1]" ng-change="setCoordinates(coordinates)">'+
							'</div>'+
						'</div>'+
					'</div>',
				scope: { mapComponentCoordinatesEditor: "=", place: "=?" },
				link: function( scope, element, attrs, tabsCtrl ) {

					scope.place = scope.place || {};

					var marker, coordinates = mapService.solveCoordinates( scope.mapComponentCoordinatesEditor );

					if (!coordinates) return;

					mapService.generateMap( element[0].children[0], { center: coordinates }).then(function( map ) {

						map.on( 'click', function onClick( evt ) {
							scope.setCoordinates( evt.latlng );
						});

						marker = mapService.Leaflet.marker( coordinates ).addTo( map );

						scope.setCoordinates( coordinates );
					});

					scope.setCoordinates = function( _coordinates ) {
						var coordinates;
						if (angular.isString( _coordinates )) {
							coordinates = _coordinates.split( "," );
						} else if (angular.isObject( _coordinates )) {
							if (angular.isArray( _coordinates )) {
								coordinates = _coordinates;
							} else if (_coordinates.lat != null && _coordinates.lng != null) {
								coordinates = [ _coordinates.lat, _coordinates.lng ];
							}
						}

						if (coordinates && angular.isNumber(coordinates[0]) && !isNaN(coordinates[0]) && angular.isNumber(coordinates[1]) && !isNaN(coordinates[1])) $timeout(function() {

							scope.coordinates = scope.place.coordinates = coordinates.map( parseFloat );

							marker.setLatLng( scope.place.coordinates );
						});
					}
				}
			};
		}
	}



	angular.module( "mapServiceModule", [] ).run([ "modalOpenerGenerator", "mapService", "$templateCache", function( modalOpenerGenerator, mapService, $templateCache ) {

		modalOpenerGenerator.generate( "map-component-view", { }, [ "coordinates" ]);

		modalOpenerGenerator.generate( "map-component-coordinates-editor", {
			keyboard: false,
			backdrop: "static"
		}, [ "place" ]);
	}])
	.service(   "mapService",                    MapService )
	.directive( "mapComponentView",              MapComponentViewDirective )
	.directive( "mapComponentCoordinatesEditor", MapComponentCoordinatesEditorDirective );

})();
