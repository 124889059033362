
/**
 * Class SearchHistoryContext
 */
class SearchHistoryContext {

	/**
	 *
	 */
	static MAX_LENGTH = 10; // jshint ignore:line

	/**
	 * @param {String} key -
	 * @param {Array} history -
	 */
	constructor( key, history = [] ) {

		this.key = key;
	
		this.setHistory( history );
	}
	/**
	 * Nouda korkeintaan max_count kappaletta hakuhistoriaa
	 * @param {int} max_count - 
	 * @return {Array}
	 */
	getItems( max_count = 5 ) {

		return (this.get( max_count ) || []).map( v => v.item );
	}

	/**
	 * Nouda korkeintaan max_count kappaletta historiaa
	 * @param {int} max_count - 
	 * @return {Array}
	 */	
	get( max_count = 5 ) {

		return this.history.slice( 0, max_count );
	}

	/**
	 * Lisätään uusi kohde historiaan
	 * @param {String} item -
	 */	
	put( item ) {
	
		for (let i = this.history.length - 1; i >= 0; i--) {
			if (this.history[i].item == item) this.history.splice( i, 1 );
		}

		this.history.unshift({ timestamp: Date.now(), item });

		try {
			let history_overflow = this.history.length - SearchHistoryContext.MAX_LENGTH;
			if (history_overflow > 0) {
				this.history.splice( SearchHistoryContext.MAX_LENGTH, history_overflow );
			}
		} catch (error) {
			console.warn( error );
		}
		
		SearchHistoryService.saveContexts();
	}

	/**
	 * Asetetaan kontekstin historia
	 * @param {Array} -
	 */
	setHistory( history = [] ) {
		this.history = [ ...history ];
	}

	static sort( a, b ) {

		if (a.timestamp && b.timestamp) {
			return b.timestamp - a.timestamp;
		}

		return 0;
	}
}

/**
 * Class SearchHistoryContextGroup
 * @extends SearchHistoryContext
 */
class SearchHistoryContextGroup extends SearchHistoryContext {

	constructor( contexts ) {
		
		super( null );
		
		this.contexts = contexts;
	}

	/**
	 * Nouda korkeintaan max_count kappaletta historiaa
	 * @param {int} max_count - 
	 * @return {Array}
	 */	
	get( max_count = 5 ) {

		let items = [];

		this.contexts.forEach( context => {
			items = items.concat( context.get( max_count ));
		});

		items.sort( SearchHistoryContext.sort );

		return items.slice( 0, max_count );
	}
	
	put() {}
}

/**
 * Class SearchHistoryService
 */
export default class SearchHistoryService {
	
	static contexts = {}; // jshint ignore:line

	static $inject = [ "$User" ]; // jshint ignore:line
	
	constructor( $User ) {
		
		SearchHistoryService.loadContexts = () => {

			let contexts = $User.loadLocalConfig( 'dashboard/searchHistoryContexts' );

			for (let key in contexts) {
				
				let history = contexts[ key ];
				
				if (!SearchHistoryService.contexts[ key ]) {
					SearchHistoryService.contexts[ key ] = new SearchHistoryContext( key, history );
				}

				SearchHistoryService.contexts[ key ].setHistory( history );
			}
		}

		SearchHistoryService.saveContexts = () => {

			let contexts = {};

			for (let context in SearchHistoryService.contexts) {
				
				contexts[ context ] = [ ...SearchHistoryService.contexts[ context ].history ];
				
				if (!contexts[ context ] || contexts[ context ].length == 0) delete contexts[ context ];

			}
			
			$User.saveLocalConfig( 'dashboard/searchHistoryContexts', contexts );
		}
	}
 
	/**
	 * Nouda hakukonteksti avaimen perusteella
	 * @param {Array|String|SearchHistoryContext} context - 
	 * @return {null|SearchHistoryContext|SearchHistoryContextGroup}
	 */
	getContext( context ) {

		if (Array.isArray( context )) {

			if (context.length == 1) {
		
				return this.buildContext( context[0] );
			}
	
			return this.getContexts( context );
		}
		
		return this.buildContext( context );
	}
	
	/**
	 * Nouda hakukontekstiryhmä useamman avaimen perusteella
	 * @param {Array} context_keys - 
	 * @return {SearchHistoryContextGroup}
	 */
	getContexts( context_keys ) {

		return new SearchHistoryContextGroup( context_keys.map( ctx => this.buildContext( ctx )));
	}

	/**
	 * Hae tai luo hakukonteksti avaimen perusteella
	 * @param {String|SearchHistoryContext} context - 
	 * @return {null|SearchHistoryContext}
	 */
	buildContext( context ) {
		
		if (context instanceof SearchHistoryContext) context = context.key;
		
		SearchHistoryService.loadContexts();
		
		if (typeof context == "string") {

			if (!SearchHistoryService.contexts[ context ]) {
				SearchHistoryService.contexts[ context ] = new SearchHistoryContext( context );
			}

			context = SearchHistoryService.contexts[ context ];
		}

		if (context instanceof SearchHistoryContext) return context;
	}
}
